"use client";
import { verifyAndDecodeToken } from "@/utils/jwt";
import axiosInstance, { updateAxiosToken } from "@/utils/axiosInstance";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import { removePersistRoot, setPersistRoot } from "@/utils/persistRoot";

interface IAuthContext {
  logout: () => void;
  login: (data: any) => void;
  reToken: () => void;
  userData: IUser | null;
  token: string;
  userMetadata: any;
}

interface IUser {
  id: string;
  email: string;
  username: string;
  status: boolean;
  role: number;
}

const AuthContext = createContext<IAuthContext>({
  logout: () => {},
  login: async (token: string) => {},
  reToken: async () => {},
  userData: null,
  token: "",
  userMetadata: null,
});

export function AuthProvider({ children }: any) {
  const [userData, setUserData] = useState({} as any);
  const [userMetadata, setUserMetadata] = useState({} as any);
  const [token, setToken] = useState("");

  const logout = useCallback(async () => {
    setUserData(null);
    setUserMetadata(null);
    setToken("");
    removePersistRoot();
    try {
      await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/logout`,
        {},
        { withCredentials: true }
      );
      //console.log("Logout success");
    } catch (error) {
      //console.error("Error during logout:", error);
      throw new Error("Error during logout");
    }
  }, []);

  const login = useCallback(async (token: string) => {
    const { error, data } = await verifyAndDecodeToken(token);

    if (error) {
      //console.error("Error verifying token:", error);
      throw new Error("Error verifying token", error);
    }
    setToken(token);
    updateAxiosToken(token);
    setUserData(data);

    const userData = {
      email: data?.email,
      username: data?.username,
    };

    setPersistRoot(userData, "userData");
  }, []);

  const reToken = useCallback(async () => {
    const token = await getTkn();

    try {
      const res = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/retoken`,
        { token },
        { withCredentials: true }
      );
      await login(res.data.token);
    } catch (err: any) {
      //console.error("Error during reToken:", err);
      throw new Error("Error during reToken", err);
    }
  }, [login]);

  const getTkn = async () => {
    try {
      const tokenRes = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/token`,
        { withCredentials: true }
      );
      return tokenRes.data.token;
    } catch (err: any) {
      //console.error("Error fetching token:", err);
      return null;
    }
  };

  useEffect(() => {
    //console.log("Fetching token and user data");
    const fetchTokenAndUserData = async () => {
      try {
        const tkn = await getTkn();

        if (!tkn) {
          // Manejar el caso donde no hay token (equivalente a un 401 silencioso)
          //console.warn("No autorizado: el usuario no ha iniciado sesión");
          return null;
        }

        setToken(tkn);
        const { data, error } = await verifyAndDecodeToken(tkn);

        if (error) {
          console.warn("Error verifying token:", error);
          return null;
        }

        await reToken();

        try {
          const userMetadataRes = await axiosInstance.get(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/userdata/${data?.id}`,
            { withCredentials: true }
          );
          setUserMetadata(userMetadataRes.data);

          const userDataRes = await axiosInstance.get(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/${data?.id}`,
            { withCredentials: true }
          );
          setUserData(userDataRes.data.user);
        } catch (err: any) {
          console.warn("Error fetching user data:", err);
          return null;
        }
      } catch (err: any) {
        console.warn("Error fetching token:", err);
        return null;
      }
    };
    fetchTokenAndUserData();
  }, [reToken]);

  return (
    <AuthContext.Provider
      value={{
        logout,
        login,
        reToken,
        userData,
        token,
        userMetadata,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
